import './App.css';
//import Dashboard from './components/Dashboard';
import AppRoutes from './Routes';
import { SpeedInsights } from "@vercel/speed-insights/react"



function App() {
  return (
    <>
      <AppRoutes/>
      <SpeedInsights/>

    </>
  );
}

export default App;
