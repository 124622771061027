import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
//import Dashboard from './components/Dashboard';
import About from './components/pages/About';
import Agenda from './components/pages/Agenda';
import Partners from './components/pages/Partners';
import WhoAttends from './components/pages/WhoAttends';
import Homepage from './components/pages/Homepage'
import Sponsor from './components/pages/Sponsor';
import JoinUs from './components/pages/JoinUs';



import IndividualForm from './components/pages/IndividualForm';
import IndividualForm2 from './components/pages/individual-form-2.js';

import CompanyBrandInfo from './components/pages/CompanyBrandInfo.js';
import CompanyBrandInfo2 from './components/pages/CompanyBrandInfo2.js'


const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Homepage/>} />
        <Route path='/about' element={<About/>}/>
        <Route path='/agenda' element={<Agenda/>}/>
        <Route path='/partners' element={<Partners/>}/>
        <Route path='/whoattends' element={<WhoAttends/>}/>
        <Route path='/sponsor' element= {<Sponsor/>}/>
        <Route path='/jointoday' element={<JoinUs/>}/>
        <Route path='/indform' element={<IndividualForm/>}/>
        <Route path='/individual-form-2' element={<IndividualForm2/>}/>
        <Route path='/companybrandinfo' element={<CompanyBrandInfo/>}/>
        <Route path='/Companybrandinfo2' element={<CompanyBrandInfo2/>}/>


      </Routes>
    </Router>
  );
};

export default AppRoutes;
