import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import '../../css/About.css'



function About() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Rooted Expo - About</title>
      </Helmet>
      <div className="about-page">
        <Navbar />
        <main className="about-main">
          <div className="about-content">
            <div className="about-header">
              <h1 className="about-title">
                Welcome to <span className="title-highlight">Rooted Expo</span>
              </h1>
              <p className="about-subtitle">
                Texas' premier trade show dedicated to the growing Consumer Packaged Goods (CPG) industry.
              </p>
            </div>

            <div className="about-section">
              <h2 className="section-title">Discover the Heart of Texas at Waco!</h2>
              <p className="section-text">
                Nestled at the crossroads of Texas' major cities, Waco stands as a vibrant and strategically chosen location for the Rooted Expo. Celebrated for its rich history and dynamic community spirit, Waco provides not just a venue, but a welcoming atmosphere steeped in true Texan hospitality.
              </p>

              <div className="feature-grid">
                <div className="feature-card">
                  <h3 className="feature-title">Central and Accessible</h3>
                  <p className="feature-text">
                    Waco is the ideal meeting point for attendees traveling from all corners of Texas. Its central location ensures convenient access whether you're coming from Dallas, San Antonio, Austin, or Houston.
                  </p>
                </div>

                <div className="feature-card">
                  <h3 className="feature-title">A Community of Innovation</h3>
                  <p className="feature-text">
                    Waco's thriving business environment and cultural richness make it a beacon for professionals and consumers alike. The city pulses with a spirit of entrepreneurship that complements the innovative essence of the CPG industry.
                  </p>
                </div>
              </div>

              <div className="join-section">
                <h2 className="section-title">Join us at Rooted Expo</h2>
                <p className="section-text">
                  Where the charm of Waco enhances our gathering of industry leaders, brands, and visionaries. It's not just about location; it's about experiencing the heartbeat of Texas. This is where you connect, learn, and grow in the company of fellow trailblazers who are as passionate about the future of CPG as you are.
                </p>
              </div>

              <div className="cta-container">
                <a href="www.google.com" className="cta-button">
                  Learn More About Our Event
                </a>
              </div>
            </div>

            {/* Speaker Grid */}
            <div className="speaker-section">
              <h2 className="section-title">Meet the awesome team behind Rooted Expo.</h2>
              <p className="section-subtitle">
                We’re bridging the gap between the world’s oldest and largest industry and a future where technology can be used to solve our hardest problems.
              </p>
              <div className="speaker-grid">
                {/* Speaker Card 1 */}
                <div className="speaker-card">
                  <img src="path-to-image-1.jpg" alt="Katrina Tolentino" className="speaker-image" />
                  <div className="speaker-info">
                    <h3 className="speaker-name">Katrina Tolentino</h3>
                    <p className="speaker-title">Executive Director of Naturally Network</p>
                  </div>
                </div>
                
                {/* Speaker Card 2 */}
                <div className="speaker-card">
                  <img src="path-to-image-2.jpg" alt="Kat Weaver" className="speaker-image" />
                  <div className="speaker-info">
                    <h3 className="speaker-name">Kat Weaver</h3>
                    <p className="speaker-title">Founder of Power to Pitch</p>
                  </div>
                </div>
                
                {/* Speaker Card 3 */}
                <div className="speaker-card">
                  <img src="path-to-image-3.jpg" alt="Felipe Vega" className="speaker-image" />
                  <div className="speaker-info">
                    <h3 className="speaker-name">Felipe Vega</h3>
                    <p className="speaker-title">Founder, Strategic Business Advisor</p>
                  </div>
                </div>
                
                {/* Speaker Card 4 */}
                <div className="speaker-card">
                  <img src="path-to-image-4.jpg" alt="Richard Riccardi" className="speaker-image" />
                  <div className="speaker-info">
                    <h3 className="speaker-name">Richard Riccardi</h3>
                    <p className="speaker-title">Co-Founder of DFW CPG and Writer and CPG Advocate</p>
                  </div>
                </div>
              </div>

              {/* Call to Action */}
              <div className="cta-container">
                <a href="#" className="cta-button-alt">
                  Get Started
                </a>
              </div>
            </div>

          </div>
        </main>
        <Footer />
      </div>
    </HelmetProvider>
  );
}

export default About;