import React from "react";
import "../../css/BrandCarousel.css";

// Import company logos
import JumpstartCPG from '../../images/brandimages/jumpstartcpg.png';
import Smurfit from '../../images/brandimages/smurfit.png';
import Rangeme from '../../images/brandimages/rangeme.png';
//import Power2Pitch from '../../images/brandimages/power2pitch.png';

const brands = [
  { name: "Jumpstart CPG", logo: JumpstartCPG },
  { name: "Smurfit", logo: Smurfit },
  { name: "RangeMe", logo: Rangeme },
  //{ name: "Power 2 Pitch", logo: Power2Pitch },
  //Change to admin panel at some point so I dont have to update this 
];

const BrandCarousel = () => {
  return (
    <div className="carousel-container">
      <div className="carousel-track">
        {brands.map((brand, index) => (
          <div key={index} className="carousel-item">
            <img
              src={brand.logo}
              alt={brand.name}
              className="carousel-image"
            />
          </div>
        ))}
        {/* Duplicate the brand list to make the scrolling continuous */}
        {brands.map((brand, index) => (
          <div key={index + brands.length} className="carousel-item">
            <img
              src={brand.logo}
              alt={brand.name}
              className="carousel-image"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BrandCarousel;
