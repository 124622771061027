import React, { useState } from 'react';
import { Dialog } from '@headlessui/react';
import Logo from '../images/Isologo.png';
import '../css/Navbar.css';

const navigation = [
 // { name: 'About', href: '/about' },
  //{ name: 'Agenda', href: '/agenda' },
 // { name: 'Partners', href: '/partners' },
  //{ name: 'Who Attends', href: '/whoattends' },
  { name: 'Become a Sponsor', href: '/sponsor', className: 'register-button' },
];

export default function Navbar({ blend }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className={`navbar ${blend ? 'navbar-blend' : ''}`}>
      <nav className="navbar-content">
        <div className="navbar-logo">
          <a href="/" className="logo-link">
            <span ></span>
            <img alt="Rooted Expo Logo" src={Logo} className="logo-image" />
          </a>
        </div>
        <div className="mobile-menu-button">
          <button type="button" onClick={() => setMobileMenuOpen(true)}>
            <span className="sr-only">Open main menu</span>
            <svg className="menu-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>
        <div className="desktop-menu">
          {navigation.map((item) => (
            <a key={item.name} href={item.href} className={item.className || 'menu-item'}>
              {item.name}
            </a>
          ))}
        </div>
      </nav>
      <Dialog open={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)} className="mobile-menu-dialog">
        <div className="dialog-overlay" />
        <Dialog.Panel className="dialog-panel">
          <div className="dialog-header">
            <a href="/" className="logo-link">
              <span className="sr-only">Rooted Expo</span>
              <img alt="Rooted Expo Logo" src={Logo} className="logo-image" />
            </a>
            <button type="button" onClick={() => setMobileMenuOpen(false)} className="close-button">
              <span className="sr-only">Close menu</span>
              <svg className="close-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div className="dialog-content">
            <div className="mobile-nav-items">
              {navigation.map((item) => (
                <a key={item.name} href={item.href} className={item.className || 'mobile-menu-item'}>
                  {item.name}
                </a>
              ))}
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
