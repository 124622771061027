import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';  // Import useNavigate for routing
import Navbar from '../Navbar';
import Footer from '../Footer';
import windmill from '../../images/Joinus.png'; 
import '../../css/CompanyBrandInfo.css'; 

function CompanyBrandInfo() {
  const [formData, setFormData] = useState({
    companyName: '',
    primaryContact: '',
    email: '',
    phone: '',
    website: '',
    address: '',
    brandDescription: '',
    categories: [''],
    revenue: '',
    targetMarket: '',
  });

  const navigate = useNavigate();  // Initialize useNavigate for routing

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked ? [...(prevData[name] || []), value] : prevData[name].filter((v) => v !== value),
      }));
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleNext = (e) => {
    e.preventDefault();
    navigate('/Companybrandinfo2', { state: { formData } });  // Navigate to the second form with formData
  };

  return (
    <div className="company-brand-info-container">
      <Navbar />
      <div className="content">
        <div className="company-brand-info-wrapper">
          <div className="image-section">
            <img src={windmill} alt="Windmill" />
          </div>

          <form className="company-brand-form" onSubmit={handleNext}>
            <h2>COMPANY INFORMATION</h2>
            <div className="form-grid">
              <div className="form-group">
                <label htmlFor="companyName">Company Name</label>
                <input
                  type="text"
                  id="companyName"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="primaryContact">Primary Contact Name</label>
                <input
                  type="text"
                  id="primaryContact"
                  name="primaryContact"
                  value={formData.primaryContact}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email Address</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Phone Number</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="website">Company Website</label>
                <input
                  type="text"
                  id="website"
                  name="website"
                  value={formData.website}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="address">Company Address</label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                />
              </div>
            </div>

            <h2>BRAND INFORMATION</h2>
            <div className="form-group">
              <label htmlFor="brandDescription">Briefly describe your brand and its product offerings:</label>
              <textarea
                id="brandDescription"
                name="brandDescription"
                value={formData.brandDescription}
                onChange={handleChange}
              />
            </div>

            <div className="checkbox-section">
              <h3>What category does your brand primarily fall under?</h3>
              {['Food & Beverage', 'Health & Wellness', 'Beauty & Personal Care', 'Household Goods', 'Pet Products', 'Other'].map((category) => (
                <label key={category}>
                  <input
                    type="checkbox"
                    name="categories"
                    value={category}
                    checked={formData.categories.includes(category)}
                    onChange={handleChange}
                  />
                  {category}
                </label>
              ))}
            </div>

            <div className="checkbox-section">
              <h3>What is the size of your brand in terms of annual revenue?</h3>
              {['Under $1 million', '$1 million - $5 million', '$5 million - $10 million', '$10 million - $50 million', 'Over $50 million'].map((revenue) => (
                <label key={revenue}>
                  <input
                    type="radio"
                    name="revenue"
                    value={revenue}
                    checked={formData.revenue === revenue}
                    onChange={handleChange}
                  />
                  {revenue}
                </label>
              ))}
            </div>

            <div className="checkbox-section">
              <h3>What is your target market within the CPG space?</h3>
              {['Local (Texas)', 'Regional (Southwest)', 'National', 'International'].map((market) => (
                <label key={market}>
                  <input
                    type="radio"
                    name="targetMarket"
                    value={market}
                    checked={formData.targetMarket === market}
                    onChange={handleChange}
                  />
                  {market}
                </label>
              ))}
            </div>

            <button type="submit" className="submit-button">Next</button>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default CompanyBrandInfo;
