import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import '../../css/Agenda.css';
import MainImage from '../../images/image.png'; // Make sure this path is correct

function Agenda() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Rooted Expo - Agenda</title>
      </Helmet>
      <div className="agenda-page">
        <div className="navbar-placeholder"></div> {/* Prevent overlap */}
        <Navbar />
        <main className="main-content">
          <div className="hero-section">
            <span className="tag">Premium</span>
            <h1 className="hero-title">Personalized and<br />total service.</h1>
            <p className="hero-text">
              At sint tollit veritus duo, ex tota inimicus dissentiunt usu. Dico tamquam
              perfecto usu id. Eu nec option perfecto vituperatoribus. Ea qui congue
              patrioque, quo ei recteque consequuntur.
            </p>
          </div>
        </main>
        <section className="option-section">
          <h2 className="option-title">Here's a different option for you.</h2>
          <p className="option-text">Eos in maluisset mediocritatem. Cu qui admodum inimicus, te mei debet qualisque.</p>
          
          {/* Replace the placeholder with your image */}
          <img src={MainImage} alt="Descriptive Alt Text" className="option-image" />
        </section>
        
        {/* Testimonial Section */}
        <section className="testimonial-section">
          <div className="testimonial-card">
            <h3 className="testimonial-title">What The People Say</h3>
            <blockquote className="testimonial-quote">
              “Great customer service, kudos for doing things the right way rather than the easy way. I am still getting compliments about the food served at my wedding.”
            </blockquote>
            <div className="testimonial-author">
              <img src="path-to-author-image.jpg" alt="Author" className="author-image" />
              <p className="author-name">Paul Morgan</p>
            </div>
          </div>
        </section>

        {/* New Content Section */}
        <hr className="content-divider" />
        <section className="content-section">
          <div className="content-block">
            <div className="content-text">
              <h3 className="content-title">Ex tempor euismod sapientem ouis lexor</h3>
              <p className="content-subtitle">singulis, ius ex mollis veritus.</p>
              <p className="content-description">
                Possit tritani consectetuer eu his, nusquam minimum ut per, eu eos ocurreret
                splendide hendrerit. Dicant placerat democritum eos id, sit mollis invidunt
                mediocritatem ei, ne fuisset dolores periculis sed.
              </p>
            </div>
            <div className="content-image">
              <img src="path-to-first-image.jpg" alt="First Dish" />
            </div>
          </div>

          <div className="content-block">
            <div className="content-image">
              <img src="path-to-second-image.jpg" alt="Second Dish" />
            </div>
            <div className="content-text">
              <h3 className="content-title">Te elit antiopam vix zril recusabo ut pri</h3>
              <p className="content-subtitle">Quas suscipit ad mea verear vivendo tincidunt.</p>
              <p className="content-description">
                Pri cu dico labores officiis, odio principes complectitur ad sea. Sea id
                doctus forensibus, nec lorem vocent aliquam eu. Aliquid definitiones id cum,
                ad meliore perpetua referrentur sed. Quas suscipit ad mea verear vivendo tincidunt.
              </p>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </HelmetProvider>
  );
}

export default Agenda;
