import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import '../../css/Partners.css';

function Partners() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Rooted Expo - Sponsorships</title>
      </Helmet>
      <div className="sponsorships-page">
        <Navbar />
        <main className="sponsorships-main">
          <div className="sponsorships-hero">
            
            <h1 className="hero-title">Support Texas CPG.</h1>
            <p className="hero-description">
              Supporting Texas CPG (Consumer Packaged Goods) brands means investing in the heartbeat of a thriving local economy. Texas is home to a diverse and innovative CPG sector that reflects the state’s entrepreneurial spirit and commitment to quality.
              By becoming a sponsor, you’ll not only gain visibility among industry leaders and emerging brands but also play a crucial role in fostering growth and innovation within the state’s dynamic marketplace. Your support empowers Texas-based businesses to reach new heights, strengthens community ties, and aligns your brand with the values of excellence, resilience, and local pride.
            </p>
          </div>

          <section className="sponsorship-options">
            <h2 className="section-title">We offer multiple options.</h2>
            <p className="section-description">
              Kid’s birthday party? Yup, we can cover that. A wedding? No problem, we got you. You get the idea.
            </p>

            <div className="pricing-cards">
              <div className="pricing-card">
                <h3 className="pricing-card-title">Small Party</h3>
                <p className="pricing-card-price">150 USD</p>
                <p className="pricing-card-description">Perfect for small gatherings.</p>
                <ul className="pricing-card-features">
                  <li>0-50 Guests</li>
                  <li>Non-alcoholic beverages</li>
                  <li>Snacks</li>
                  <li>Lorem ipsum dolor</li>
                </ul>
                <div className="cta-container">
                  <a href="#" className="cta-button">Get Started</a>
                </div>
              </div>

              <div className="pricing-card">
                <h3 className="pricing-card-title">Medium Party</h3>
                <p className="pricing-card-price">350 USD</p>
                <p className="pricing-card-description">Ideal for medium-sized events.</p>
                <ul className="pricing-card-features">
                  <li>50-100 Guests</li>
                  <li>Non-alcoholic beverages</li>
                  <li>Snacks</li>
                  <li>Lorem ipsum dolor</li>
                </ul>
                <div className="cta-container">
                  <a href="#" className="cta-button">Get Started</a>
                </div>
              </div>

              <div className="pricing-card">
                <h3 className="pricing-card-title">Large Party</h3>
                <p className="pricing-card-price">750 USD</p>
                <p className="pricing-card-description">Great for large gatherings.</p>
                <ul className="pricing-card-features">
                  <li>100-200 Guests</li>
                  <li>Non-alcoholic beverages</li>
                  <li>Snacks</li>
                  <li>Lorem ipsum dolor</li>
                </ul>
                <div className="cta-container">
                  <a href="#" className="cta-button">Get Started</a>
                </div>
              </div>
            </div>
          </section>

          <section className="benefits-section">
            <div className="benefits-grid">
              <div className="benefit-card">
                <div className="benefit-icon">✔</div>
                <h3 className="benefit-title">Pro ad constituo</h3>
                <p className="benefit-description">Ne usu illud albucius abhor reant, partiendo scriptorem mel ne.</p>
              </div>
              <div className="benefit-card">
                <div className="benefit-icon">✔</div>
                <h3 className="benefit-title">Eam commodo</h3>
                <p className="benefit-description">Ne his fabellas efficiendi. Eum te rebum accusamus, et omnium option.</p>
              </div>
              <div className="benefit-card">
                <div className="benefit-icon">✔</div>
                <h3 className="benefit-title">Reormidans eis</h3>
                <p className="benefit-description">Maluisset imperdiet ea vel. Justo doctus dissentiet in nec.</p>
              </div>
            </div>
          </section>

          <section className="faq-section">
            <h2 className="section-title">Got a question about our pricing model?</h2>
            <p className="section-description">
              Te omnis oporteat vis, omnes reprehendunt nam in. Cum salutatus democritum argumentum an.
            </p>

            <div className="faq-grid">
              <div className="faq-card">
                <h3 className="faq-title">Usu tractatos accommodare ei pri alii invidunt eu?</h3>
                <p className="faq-description">
                  Molestiae adolescens his cu, ius facete scripta ad, ea ferri fastidi iudicabit pri. Vim id soleat aliquip adipisc, at qui discere denique salutatus, no appareat abhorreant quo.
                </p>
              </div>
              <div className="faq-card">
                <h3 className="faq-title">Mel nostro fabellas tractatos in?</h3>
                <p className="faq-description">
                  Eam soluta noluisse lobortis no, eos in nibh patrioque. Decore populo detracto eu has. Viderer prodesset expetendis ei mel.
                </p>
              </div>
              <div className="faq-card">
                <h3 className="faq-title">Mei ne eirmod omittam adipisci?</h3>
                <p className="faq-description">
                  Id sed labores dolorum veritus, falli aeque id vis. Aliquip aperiri facilisis ei eam, an eos agam vivendo platonem. Quo volumus scaevola te.
                </p>
              </div>
              <div className="faq-card">
                <h3 className="faq-title">No facilis molestie mei?</h3>
                <p className="faq-description">
                  Videerr molestiae vim id, equidem oporteer consulatu eu nam. Et solum verterem eleifend nam. Doming latine scriptorem ex nec.
                </p>
              </div>
            </div>
          </section>

          <section class="cta-section">
              <h2 class="section-title">Party On</h2>
              <p class="section-description">
                Quas suscipit ad mea. Pri cu dico labores officiis odio principes complectitur ad sea.
              </p>
              <a href="#" class="cta-button">Get Started</a>
            </section>
            <div class="footer"></div>


        </main>
        <Footer />
      </div>
    </HelmetProvider>
  );
}

export default Partners;