import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import '../../css/WhoAttends.css';

function WhoAttends() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Rooted Expo - Who Attends</title>
      </Helmet>
      <div className="who-attends-page">
        <div className="navbar-placeholder"></div> {/* Prevent overlap */}
        <Navbar />
        <main className="who-attends-main">
          <div className="who-attends-content">
            <h1 className="who-attends-title">
              Who Attends Rooted Expo
            </h1>
            <div className="who-attends-description">
              <p>
                <strong>Rooted Expo is the must-attend event</strong> for CPG professionals, brand owners, retailers, distributors, and marketers focused on the Texas market and beyond. Whether you're a startup seeking exposure or a seasoned brand looking to expand your network, the expo brings together the brightest minds and most innovative players in the industry.
              </p>
            </div>

            {/* Icons Section */}
            <div className="icons-section">
              {/* TODO: Insert icons of types of companies & industry */}
              <p className="icons-placeholder">
                [Insert icons here]
              </p>
            </div>

            {/* Quotes Section */}
            <div className="quotes-section">
              {/* TODO: Insert quotes here */}
              <div className="quote-card">
                <p className="quote-text">"[Insert quote here]"</p>
                <p className="quote-author">- [Quote Author]</p>
              </div>
              {/* Add more quote-card divs as needed */}
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </HelmetProvider>
  );
}

export default WhoAttends;
