import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../Navbar';
import Footer from '../Footer';
import windmill from '../../images/Joinus.png';
import '../../css/IndividualForm.css';

function IndividualForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    reason: '',
    categories: [''],
    hear_about: [''],  // Use snake_case
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked ? [...(prevData[name] || []), value] : prevData[name].filter((v) => v !== value),
      }));
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleNext = (e) => {
    e.preventDefault();
    navigate('/individual-form-2', { state: { formData } });
  };

  return (
    <div className="individual-form-container">
      <Navbar />
      <div className="content">
        <div className="individual-form-wrapper">
          <div className="image-section">
            <img src={windmill} alt="Windmill" />
          </div>

          <form className="individual-form" onSubmit={handleNext}>
            <h2>PERSONAL INFORMATION</h2>
            <div className="form-grid">
              <div className="form-group">
                <label htmlFor="name">Your Name:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email Address:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Phone Number:</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </div>
            </div>

            <h2>PARTICIPANT DETAILS</h2>
            <div className="form-group">
              <label htmlFor="reason">Why are you interested in attending the Rooted Expo?</label>
              <textarea
                id="reason"
                name="reason"
                value={formData.reason}
                onChange={handleChange}
              />
            </div>

            <div className="checkbox-section">
              <h3>What category does your brand primarily fall under?</h3>
              {['Food & Beverage', 'Health & Wellness', 'Beauty & Personal Care', 'Household Goods', 'Sustainability', 'Networking Opportunities', 'Other'].map((category) => (
                <label key={category}>
                  <input
                    type="checkbox"
                    name="categories"
                    value={category}
                    checked={formData.categories.includes(category)}
                    onChange={handleChange}
                  />
                  {category}
                </label>
              ))}
            </div>

            <div className="checkbox-section">
              <h3>How did you hear about the Rooted Expo?</h3>
              {['Social Media', 'Friend/Colleague', 'Website', 'Other'].map((hearAbout) => (
                <label key={hearAbout}>
                  <input
                    type="checkbox"
                    name="hear_about"  // snake_case to match SQL
                    value={hearAbout}
                    checked={formData.hear_about.includes(hearAbout)}
                    onChange={handleChange}
                  />
                  {hearAbout}
                </label>
              ))}
            </div>

            <button type="submit" className="submit-button">Next</button>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default IndividualForm;
