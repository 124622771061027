import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';  // Use location and navigate
import Navbar from '../Navbar';
import Footer from '../Footer';
import windmill from '../../images/Joinus.png'; 
import '../../css/CompanyBrandInfo2.css'; 
import supabase from '../../supabase';  // Import Supabase

function CompanyBrandInfo2() {
  const location = useLocation();  // Retrieve data passed from the first form
  const navigate = useNavigate();
  const initialData = location.state.formData;  // Get formData from the first form

  const [formData, setFormData] = useState({
    boothSize: [''],
    specialAccommodations: '',
    exhibitedBefore: '',
    specialRequirements: '',
    comments: '',
  });

  const [showPopup, setShowPopup] = useState(false);  // State for managing popup

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked ? [...(prevData[name] || []), value] : prevData[name].filter((v) => v !== value),
      }));
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const completeFormData = { ...initialData, ...formData };  // Combine data from both forms

    try {
      const { data, error } = await supabase
        .from('vendor')  // Insert into the 'vendor' table
        .insert([completeFormData]);

      if (error) {
        console.error('Error inserting data:', error);
      } else {
        console.log('Data inserted successfully:', data);
        setShowPopup(true);  // Show the popup after successful submission

        // Redirect to the homepage after 5 seconds
        setTimeout(() => {
          navigate('/');
        }, 5000);
      }
    } catch (err) {
      console.error('Error during submission:', err);
    }
  };

  return (
    <div className="exhibition-needs-container">
      <Navbar />
      <div className="content">
        <div className="exhibition-needs-wrapper">
          <div className="image-section">
            <img src={windmill} alt="Windmill" />
          </div>

          <form className="exhibition-form" onSubmit={handleSubmit}>
            <h2>EXHIBITION NEEDS</h2>
            <div className="checkbox-section">
              <h3>Preferred Booth Size:</h3>
              {['10x10', '10x20', '20x20', 'Custom Size'].map((booth) => (
                <label key={booth}>
                  <input
                    type="checkbox"
                    name="boothSize"
                    value={booth}
                    checked={formData.boothSize.includes(booth)}
                    onChange={handleChange}
                  />
                  {booth}
                </label>
              ))}
            </div>

            <div className="form-group">
              <h3>Do you require any special accommodations or features for your booth?</h3>
              <label>
                <input
                  type="radio"
                  name="specialAccommodations"
                  value="Yes"
                  checked={formData.specialAccommodations === 'Yes'}
                  onChange={handleChange}
                />
                Yes
              </label>
              <label>
                <input
                  type="radio"
                  name="specialAccommodations"
                  value="No"
                  checked={formData.specialAccommodations === 'No'}
                  onChange={handleChange}
                />
                No
              </label>
            </div>

            <div className="form-group">
              <label htmlFor="specialRequirements">If yes, please specify:</label>
              <textarea
                id="specialRequirements"
                name="specialRequirements"
                value={formData.specialRequirements}
                onChange={handleChange}
              />
            </div>

            <h2>ADDITIONAL INFORMATION</h2>
            <div className="form-group">
              <h3>Have you exhibited at Rooted Expo or similar events before?</h3>
              <label>
                <input
                  type="radio"
                  name="exhibitedBefore"
                  value="Yes"
                  checked={formData.exhibitedBefore === 'Yes'}
                  onChange={handleChange}
                />
                Yes
              </label>
              <label>
                <input
                  type="radio"
                  name="exhibitedBefore"
                  value="No"
                  checked={formData.exhibitedBefore === 'No'}
                  onChange={handleChange}
                />
                No
              </label>
            </div>

            <div className="form-group">
              <label htmlFor="specialRequirements">If yes, please specify:</label>
              <textarea
                id="specialRequirements"
                name="specialRequirements"
                value={formData.specialRequirements}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="comments">Please provide any additional comments or requests:</label>
              <textarea
                id="comments"
                name="comments"
                value={formData.comments}
                onChange={handleChange}
              />
            </div>

            <button type="submit" className="submit-button">Submit</button>
          </form>
        </div>
      </div>
      
      {/* Show popup after form submission */}
      {showPopup && (
        <div className="Dino-popup">
          <p>Thank you for your submission! Redirecting you to the homepage...</p>
        </div>
      )}

      <Footer />
    </div>
  );
}

export default CompanyBrandInfo2;
