import React from 'react';
import { useNavigate } from 'react-router-dom';  // Import useNavigate for routing
import Navbar from '../Navbar';
import Footer from '../Footer';
import windmill from '../../images/Joinus.png'; // Path to your windmill image
import '../../css/JoinUs.css'; // Import the corresponding CSS

function JoinUs() {
  const navigate = useNavigate();  // Initialize useNavigate for routing

  const handleIndividualClick = () => {
    navigate('/indform');  // Route to IndividualForm when clicked
  };

  const handleVendorClick = () => {
    navigate('/companybrandinfo');  // Route to CompanyBrandInfo when Vendor/Exhibitor button is clicked
  };

  return (
    <div className="join-us-container">
      <Navbar />
      <div className="content">
        <div className="join-us-content-wrapper">
          <div className="image-section">
            <img src={windmill} alt="Windmill" />
          </div>

          <div className="form-section">
            <h2>GETTING STARTED</h2>
            <p>How do you plan on participating in Rooted Expo?</p>
            <div className="button-group">
              <button className="cta-button" onClick={handleVendorClick}>Vendor/Exhibitor</button>
              <button className="cta-button" onClick={handleIndividualClick}>Individual</button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default JoinUs;
