import React, { useEffect, useState } from 'react';
import Navbar from '../Navbar';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import '../../css/Homepage.css'; 
//import BackgroundImage from '../../images/texasflag.png'; 
import Footer from '../Footer';
import supabase from '../../supabase';
import AboutUs from '../../images/Aboutus.png'
import WhyTexas from '../../images/whyTexas.png'
import Person from '../../images/person.png'
import { useNavigate } from 'react-router-dom';

//import Sponsor from './Sponsor';


import OurMissionImage from '../../images/ourMission.png'
import LeftImage from '../../images/left.png';  
import RightImage from '../../images/right.png';





import Kat from '../../peopleimages/kat.png'
import Richard from '../../peopleimages/richard.png'
import F from '../../peopleimages/f.png'
import Jeff from '../../peopleimages/jeff.png'
import Rick from '../../peopleimages/rick.png'
import Katr from '../../peopleimages/katr.png'


import BrandCarousel from './BrandCarousel';


function Homepage() {
  const navigate = useNavigate();
  const handleJoinWaitlist = () => {
    navigate('/jointoday'); // <-- Navigate to /jointoday route
  };


  const teamMembers = [
    {
      name: "Katrina Tolentino",
      role: "Executive Director of Naturally Network",
      image: Katr,
      link: "https://www.linkedin.com/in/katrinatolentino" 
    },
    {
      name: "Rick Jordan",
      role: "Managing Partner of Polsinelli & Co-Founder of DFW CPG",
      image: Rick, 
      link: "https://www.linkedin.com/in/rick-jordan-81b38316"
    },
    {
      name: "Jeff Richards",
      role: "Founder of Mooala",
      image: Jeff, 
      link: "https://www.linkedin.com/in/jeffreyrichards"
    }
  ];






  const boardMembers = [
    {
      name: "Derek Ramos",
      role: "Founder & Chief Organizer of Rooted Expo",
      image: Person, 
      link: "https://tinyurl.com/2zaxuja3" 
    },
    {
      name: "Kat Weaver",
      role: "Founder of Power to Pitch & Event Co-Chair of DFW CPG",
      image: Kat, 
      link: "https://tinyurl.com/9kfde4cs"
    },
    {
      name: "Richard G. Riccardi",
      role: "Writer, and CPG Advocate & Co-Founder of DFW CPG",
      image: Richard, 
      link: "https://tinyurl.com/mrys9736"
    },
    {
      name: "Felipe Vega",
      role: "Founder of IronClad & Board President of Naturally Austin",
      image: F, 
      link: "https://tinyurl.com/39jf9zja"
    }
  ];


  useEffect(() => {
    const collectVisitorData = async () => {
      try {
        // Collect browser-related data
        const userAgent = navigator.userAgent;
        const platform = navigator.platform;
        const language = navigator.language;
        const referrer = document.referrer;

        // Collect geolocation data (if permission is granted)
        let latitude = null;
        let longitude = null;
        if ('geolocation' in navigator) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              latitude = position.coords.latitude;
              longitude = position.coords.longitude;
              logVisitorData(userAgent, platform, language, referrer, latitude, longitude);
            },
            (error) => {
              // If geolocation is not allowed or fails, still log the other data
              logVisitorData(userAgent, platform, language, referrer, latitude, longitude);
            }
          );
        } else {
          // If geolocation is not available, log without it
          logVisitorData(userAgent, platform, language, referrer, latitude, longitude);
        }
      } catch (error) {
        console.error('Error collecting visitor data:', error);
      }
    };

    const logVisitorData = async (userAgent, platform, language, referrer, latitude, longitude) => {
      const visitorData = {
        // Exclude 'id' as it should auto-increment
        user_agent: userAgent,
        platform: platform,
        language: language,
        referrer: referrer,
        latitude: latitude,
        longitude: longitude,
        timestamp: new Date(),
      };

      // Log data to Supabase
      const { data, error } = await supabase
        .from('visitor_logs')
        .insert([visitorData]);

      if (error) {
        console.error('Error logging visitor data:', error);
      }
    };

    collectVisitorData();
  }, []);

  return (
    <HelmetProvider>
      <Helmet>
        <title>Rooted Expo - Home</title>
      </Helmet>
      <div className="homepage">
        <Navbar blend={true} /> {/* Pass a prop to make the navbar blend */}
        <header className="hero-section">
          <div className="hero-background"></div>
          <div className="hero-content">
            <h1 className="hero-title">Rooted with Texas <br></br>Brands</h1>
            <p className="hero-subtitle">Cultivating Connections and Supporting Community <br/>for the Future of Texas CPG</p>
            <strong><button className="cta-button" onClick={handleJoinWaitlist}>Join 2025 Waitlist
            </button></strong>
          </div>
        </header>
        <section className="intro-section">
          <div className="intro-content">
            <h2 className="intro-title">Our Mission</h2>
            <p className="intro-text">
              To empower CPG founders by providing a dynamic platform to showcase products, connect with buyers 
              and investors, and drive growth through collaboration. We celebrate the diverse <span className="highlight">Texas CPG</span> ecosystem, 
              fostering relationships that enhance economic prosperity and position Texas-made products on the national stage.
            </p>
          </div>
          <div className="intro-image">
            <img src={OurMissionImage} alt="CPG Products Showcase" />
          </div>
        </section>
        <section className="about-section" style={{ backgroundImage: `url(${AboutUs})` }}>
          <div className="about-container">
            <h2 className="about-title">About Us</h2>
            <p className="about-description">
              <span className="highlight">Rooted Expo</span> is more than just a trade show—it's a movement dedicated to nurturing and empowering the Consumer Packaged Goods (CPG) community in Texas. ...
            </p>
            <p className="about-description">
              Our mission is to bring together the best of Texas: the bold entrepreneurs, local pioneers, and groundbreaking products that make this state a hub for CPG innovation. Rooted in the heart of Texas, we offer an unmatched environment for brands to grow, collaborate, and expand their reach across the state and beyond.
            </p>
            <p className="about-description">
              Whether you’re an early-stage startup or an established business, Rooted Expo is the place to connect with like-minded professionals, discover new industry trends, and build relationships that will drive long-term success. With a focus on fostering community, sustainability, and local partnerships, we’re proud to support Texas brands in their journey to make a lasting impact.
            </p>
          </div>
        </section>


        <section className="why-texas-section">
          <div className="why-texas-container">
            <div className="why-texas-image">
              <img src={WhyTexas} alt="Texas Capitol Building" />
            </div>
            <div className="why-texas-content">
              <h2 className="why-texas-title">Why Texas?</h2>
              <h3 className="why-texas-subtitle">A Hub for Innovation and Growth</h3>
              <p className="why-texas-description">
                Texas’s booming economy and business-friendly environment make it an ideal location for a CPG trade show. With a focus on innovation and entrepreneurship, the state provides the perfect platform for brands to:
              </p>
              <ul className="why-texas-list">
                <li>⚜ Showcase Products</li>
                <li>⚜ Connect with Industry Leaders</li>
                <li>⚜ Explore New Growth Opportunities</li>
              </ul>
            </div>
          </div>
        </section>



        <section className="market-location-section">
          <div className="market-location-container">
            {/* Left Column */}
            <div className="market-column">
              <h3 className="market-title">Diverse and Expansive Consumer Market</h3>
              <p className="market-description">
                Home to 30.5 million residents, Texas offers a diverse consumer base with varying preferences and trends. 
                Hosting a trade show here allows brands to:
              </p>
              <ul className="market-list">
                <li>⚜ Engage directly with a large, diverse audience</li>
                <li>⚜ Gain key market insights</li>
                <li>⚜ Build relationships for national success</li>
              </ul>
              <div className="market-image">
                <img src={LeftImage} alt="Diverse Market" />
              </div>
            </div>

            {/* Right Column */}
            <div className="market-column">
              <h3 className="market-title">Strategic Central Location</h3>
              <p className="market-description">
                Located centrally, Texas provides:
              </p>
              <ul className="market-list">
                <li>⚜ Easy access for national and international attendees</li>
                <li>⚜ Major hubs like Dallas and Houston, connecting participants worldwide</li>
                <li>⚜ A role as a trade gateway, helping CPG brands expand into international markets</li>
              </ul>
              <div className="market-image">
                <img src={RightImage} alt="Strategic Location" />
              </div>
            </div>
          </div>
        </section>
        
        <section className="team-section">
          <h2 className="team-title">Our Rooted Team</h2>

          <div className="team-container">
            {/* Left Column for the Board of Directors */}
            <div className="team-category">
              <h3 className="category-title">Board Of Directors</h3>
            </div>
              {/* Grid for Board of Directors */}
              <div className="team-grid board-grid">
                {boardMembers.map((member, index) => (
                  <div key={index} className="team-card">
                    <img src={member.image} alt={`Team Member: ${member.name}`} className="team-image" />
                    <h3 className="team-name">{member.name}</h3>
                    <p className="team-role">{member.role}</p>
                    <button className="learn-more" onClick={() => window.open(member.link, '_blank')}>Learn More</button>
                  </div>
                ))}
              </div>


            {/* Left Column for Advisory Board */}
            <div className="team-category">
              <h3 className="category-title">Advisory Board</h3>
            </div>

            <div className="team-grid">
              {teamMembers.map((member, index) => (
                <div key={index} className="team-card">
                  <img src={member.image} alt={`Team Member: ${member.name}`} className="team-image" />
                  <h3 className="team-name">{member.name}</h3>
                  <p className="team-role">{member.role}</p>
                  <button className="learn-more" onClick={() => window.open(member.link, '_blank')}>Learn More</button>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section>
          <BrandCarousel/>
        </section>


        <Footer />
      </div>
    </HelmetProvider>
  );
}

export default Homepage;
