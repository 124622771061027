import React from 'react';
import '../css/footer.css';
import Logo from '../images/Isologo.png';

const navigation = {
  main: [
    { name: 'Our Mission', href: '#' },
    { name: 'Why Texas', href: '#' },
    { name: 'About Us', href: '#' },
    { name: 'The Team', href: '#' },
    { name: 'Join 2025 Waitlist', href: '#' },
    { name: 'Become a Sponsor', href: '/sponsor' },
  ],
};

export default function Footer() {
  //const currentYear = new Date().getFullYear(); //Will be called later

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-logo-text">
          <img src={Logo} className="footer-logo" alt="Rooted Expo Logo" />
          <p className="footer-description">
            Rooted Expo is Texas' premier CPG trade show, dedicated to empowering local brands by connecting them with buyers, investors, and innovators for growth and collaboration. With a focus on community and sustainability, it fosters partnerships that drive long-term success.
          </p>
        </div>
        <bold>
          <nav className="footer-nav">
            {navigation.main.map((item) => (
              <a key={item.name} href={item.href} className="footer-link">
                {item.name}
              </a>
            ))}
          </nav>
        </bold>

      </div>
    </footer>
  );
}
