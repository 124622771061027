import React, { useState } from 'react';
import '../../css/Sponsor.css';
import Navbar from '../Navbar';
import Footer from '../Footer';
import citySkyline from '../../images/joinrooted.png';
import supabase from '../../supabase';  // Assuming supabase.js is in this path

function Sponsor() {
  const [formData, setFormData] = useState({
    companyName: '',
    primaryContact: '',
    email: '',
    phone: '',
    website: '',
    address: '',
    sponsorshipLevels: [],
    goals: [],
    opportunities: [],
    additionalInfo: '',
    companyDescription: '',  // New field for company description
    sponsoredBefore: '',      // New field for sponsored similar events before (Yes/No)
    sponsorshipDetails: ''    // New field for details if previously sponsored
  });

  const [showPopup, setShowPopup] = useState(false);  // State to manage the popup

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked
          ? [...(prevData[name] || []), value]
          : prevData[name].filter((v) => v !== value),
      }));
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data, error } = await supabase
        .from('sponsorship_submissions')  // Make sure this matches your table name
        .insert([
          {
            company_name: formData.companyName,
            primary_contact_name: formData.primaryContact,
            email_address: formData.email,
            phone_number: formData.phone,
            company_website: formData.website,
            company_address: formData.address,
            sponsorship_tier: formData.sponsorshipLevels,
            primary_goals: formData.goals,
            opportunities: formData.opportunities,
            additional_info: formData.additionalInfo,
            company_description: formData.companyDescription,  // New field
            sponsored_before: formData.sponsoredBefore === 'Yes',  // New field (boolean)
            sponsorship_details: formData.sponsorshipDetails   // New field
          }
        ]);

      if (error) {
        console.error('Error inserting data:', error);
      } else {
        console.log('Data inserted successfully:', data);

        // Show the thank you popup
        setShowPopup(true);

        // Redirect to the homepage after 5 seconds
        setTimeout(() => {
          window.location.href = '/';  // Adjust the route to your homepage
        }, 5000);
      }
    } catch (err) {
      console.error('Error during submission:', err);
    }
  };

  return (
    <div className="Dino-sponsor-container">
      <Navbar />
      <div className="Dino-content">
        <div className="Dino-sponsor-content-wrapper">
          <div className="Dino-image-section">
            <img src={citySkyline} alt="City skyline" />
          </div>

          <form className="Dino-sponsor-form" onSubmit={handleSubmit}>
            <h2>COMPANY INFORMATION</h2>
            <div className="Dino-form-grid">
              <div className="Dino-form-group">
                <strong><label htmlFor="companyName">Company Name:</label></strong>
                <input
                  type="text"
                  id="companyName"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                />
              </div>
              <div className="Dino-form-group">
                <strong><label htmlFor="primaryContact">Primary Contact Name:</label> </strong>
                <input
                  type="text"
                  id="primaryContact"
                  name="primaryContact"
                  value={formData.primaryContact}
                  onChange={handleChange}
                />
              </div>
              <div className="Dino-form-group">
                <strong><label htmlFor="email">Email Address:</label></strong>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="Dino-form-group">
                <strong><label htmlFor="phone">Phone Number:</label></strong>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </div>
              <div className="Dino-form-group">
                <strong><label htmlFor="website">Company Website:</label></strong>
                <input
                  type="text"
                  id="website"
                  name="website"
                  value={formData.website}
                  onChange={handleChange}
                />
              </div>
              <div className="Dino-form-group">
                <strong><label htmlFor="address">Company Address:</label></strong>
                <input
                  type="text"
                  id="address"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                />
              </div>

              {/* New field: Briefly describe your company */}
              <div className="Dino-form-group">
                <strong><label htmlFor="companyDescription">Briefly describe your company and its connection to the Texas CPG industry:</label></strong>
                <textarea
                  id="companyDescription"
                  name="companyDescription"
                  value={formData.companyDescription}
                  onChange={handleChange}
                />
              </div>
            </div>

            <h2>SPONSORSHIP INTEREST</h2>
            <div className="Dino-checkbox-section">
              <h3>Please select the sponsorship tier(s) you're interested in:</h3>
              {[
                'Alamo (Level 1) Sponsor', 
                'Lone Star (Level 2) Sponsor', 
                'Longhorn (Level 3) Sponsor', 
                'Bluebonnet (Level 4) Sponsor', 
                'Friend Sponsor (Level 5)', 
                'Custom Sponsor Package'
              ].map((level) => (
                <label key={level}>
                  <input
                    type="checkbox"
                    name="sponsorshipLevels"
                    value={level}
                    checked={formData.sponsorshipLevels.includes(level)}
                    onChange={handleChange}
                  />
                  {level}
                </label>
              ))}
            </div>

            {/* New field: Have you sponsored similar events before? */}
            <div className="Dino-form-group">
              <h3>Have you sponsored similar events before?</h3>
              <label>
                <input
                  type="radio"
                  name="sponsoredBefore"
                  value="Yes"
                  checked={formData.sponsoredBefore === 'Yes'}
                  onChange={handleChange}
                />
                Yes
              </label>
              <label>
                <input
                  type="radio"
                  name="sponsoredBefore"
                  value="No"
                  checked={formData.sponsoredBefore === 'No'}
                  onChange={handleChange}
                />
                No
              </label>
            </div>

            {/* New field: If yes, please provide details */}
            {formData.sponsoredBefore === 'Yes' && (
              <div className="Dino-form-group">
                <label htmlFor="sponsorshipDetails">If yes, please provide details:</label>
                <textarea
                  id="sponsorshipDetails"
                  name="sponsorshipDetails"
                  value={formData.sponsorshipDetails}
                  onChange={handleChange}
                />
              </div>
            )}

            <div className="Dino-checkbox-section">
              <h3>What are your primary goals for sponsoring Rooted Expo?</h3>
              {[
                'Brand Visibility', 
                'Lead Generation', 
                'Networking with Industry Leaders', 
                'Supporting Local Businesses', 
                'Product Launch'
              ].map((goal) => (
                <label key={goal}>
                  <input
                    type="checkbox"
                    name="goals"
                    value={goal}
                    checked={formData.goals.includes(goal)}
                    onChange={handleChange}
                  />
                  {goal}
                </label>
              ))}
            </div>

            <div className="Dino-checkbox-section">
              <h3>Which of the following opportunities would you be interested in?</h3>
              {[
                'Speaking Engagement', 
                'Booth Space', 
                'Product Sampling', 
                'Digital Advertising', 
                'Event Program Listing', 
                'VIP Event Access', 
                'Other'
              ].map((opportunity) => (
                <label key={opportunity}>
                  <input
                    type="checkbox"
                    name="opportunities"
                    value={opportunity}
                    checked={formData.opportunities.includes(opportunity)}
                    onChange={handleChange}
                  />
                  {opportunity}
                </label>
              ))}
            </div>

            <div className="Dino-form-group">
              <strong><label htmlFor="additionalInfo">
                Please provide any additional information or special requests:
              </label></strong>
              <textarea
                id="additionalInfo"
                name="additionalInfo"
                value={formData.additionalInfo}
                onChange={handleChange}
              />
            </div>

            <button type="submit" className="Dino-submit-button">Submit</button>
          </form>
        </div>
      </div>
      {showPopup && (
        <div className="Dino-popup">
          <p>Thank you for your submission! Redirecting you to the homepage...</p>
        </div>
      )}
      <Footer />
    </div>
  );
}

export default Sponsor;
